<template>
	<nav class="head-tab flex-row sticky-fixed" :class="{'active': showMenu}">
		<div class="logo">
			<img src="../../assets/images/logo.png" alt="">
		</div>
		<div class="menu flex-row">
			<ul class="nav-items flex-row scroll-animate father">
				<template v-for="(item,index) in $store.state.user.isLogin ? menuList : menuList1">
					<li class="alan animated fadeInDownSmall" :class="{'active': page === item.index}" :key="index" :style="`visibility: visible; animation-delay: ${item.delay}s;`" @click="toPage(item)">
						<a><span>{{item.text}}</span></a>
					</li>
					<span v-if="index + 1!==menuList.length" class="nav-dot" :key="item.text"></span>
				</template>
				<!-- <li class="alan animated fadeInDownSmall" :style="`visibility: visible; animation-delay: .5s;`">
					<div class="login flex-row" v-if="!$store.state.user.isLogin">
						<span @click="login">登录</span>
						<em class="line"></em>
						<span @click="register">注册</span>
					</div>
				</li> -->
			</ul>
			<div class="other-box">
				<div class="menu-icon" @click="isShowMenu"></div>
			</div>
			<div class="nav-txt flex-row" :class="{'login': $store.state.user.isLogin}">
				<span class="ft-14 wc en_b">菜单</span><em class="arrow"></em>
			</div>
			<div class="user" v-if="$store.state.user.isLogin">
				<div class="user-icon"></div>
				<div class="user-menu t-center">
					<ul>
						<li @click="userTap('1')"><span>我的预约</span></li>
						<li @click="userTap('2')"><span>个人设置</span></li>
						<li @click="outLogin"><span>退出登录</span></li>
					</ul>
				</div>
			</div>
		</div>
		<login-dialog ref="loginDialog" @showRegister="register"></login-dialog>
		<register-dialog ref="registerDialog" @showLogin="login" @successRegister="regSuccess"></register-dialog>
		<success-register ref="successReg"></success-register>
		<error-dialog ref="error"></error-dialog>
	</nav>
</template>

<script>
	import loginDialog from '../user/login.vue'
	import registerDialog from '../user/register.vue'
	import successRegister from '../user/successRegister.vue'
	import errorDialog from '../user/errorDialog.vue'
	import { loginOut } from '@/api/login.js'
	export default{
		props: {
			showMenu: {
				type: Boolean,
				default: false
			},
			page: {
				type: Number,
				default: 0
			}
		},
		components: {
			loginDialog,
			registerDialog,
			successRegister,
			errorDialog
		},
		data() {
			return {
				menuList: [
					{
						index: 0,
						text: '首页',
						delay: 0,
						routerUrl: '/index'
					},
					{
						index: 1,
						text: '新闻动态',
						delay: 0.1,
						routerUrl: '/news'
					},
					{
						index: 2,
						text: '影视作品',
						delay: 0.2,
						routerUrl: '/moviesWorks'
					},
					{
						index: 3,
						text: '商务合作',
						delay: 0.3,
						routerUrl: '/cooperation'
					},
					{
						index: 4,
						text: '关于浩亿',
						delay: 0.4,
						routerUrl: '/about'
					}
				],
				menuList1: [
					{
						index: 0,
						text: '首页',
						delay: 0,
						routerUrl: '/index'
					},
					{
						index: 1,
						text: '新闻动态',
						delay: 0.1,
						routerUrl: '/news'
					},
					{
						index: 3,
						text: '商务合作',
						delay: 0.3,
						routerUrl: '/cooperation'
					},
					{
						index: 4,
						text: '关于浩亿',
						delay: 0.4,
						routerUrl: '/about'
					}
				],
			}
		},
		created() {
			this.$store.state.user.isLogin = localStorage.getItem('token') ? true : false
		},
		methods: {
			isShowMenu() {
				this.$emit('change',this.showMenu)
			},
			toPage(item) {
				document.documentElement.scrollTop = 0
				this.$store.state.user.cacheList = []
				this.$router.push(item.routerUrl)
			},
			login() {
				this.$refs.loginDialog.show()
			},
			register() {
				this.$refs.registerDialog.show()
			},
			// 成功弹窗
			regSuccess(obj) {
				this.$refs.successReg.title = obj.title
				this.$refs.successReg.show()
			},
			// 失败弹窗
			showError(obj) {
				this.$refs.error.title = obj.title
				this.$refs.error.show()
			},
			userTap(val) {
				this.$router.push({path: '/personal',query: {type: val}})
			},
			outLogin() {
				loginOut({}).then(res =>{
					if (res.success) {
						this.$message.success(res.message)
						localStorage.removeItem('token')
						localStorage.removeItem('userInfo')
						this.$store.state.user.token = ''
						this.$store.state.user.info = null
						this.$store.state.user.isLogin = false
						setTimeout(() =>{
							// this.$router.push('/')
							this.$router.go(0)
						},1000) 
					} else {
						this.$message.error('退出登录失败')
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	nav {
		justify-content: space-between;
		width: 100%;
		padding: 2%;
		transition: all .3s linear;
		.logo {
			width: 178px;
			transition: all .3s linear; 
			img {
				display: block;
				width: 100%;
			}
		}
		&.on{
			.logo {
				width: 150px;
			}
			.menu{
				width: calc(100% - 150px);
				.other-box{
					.menu-icon{
						width: 45px;
						height: 45px;
						background-size: 12px 7px;
					}
				}
				.user{
					.user-icon{
						width: 45px;
						height: 45px;
						background-size: 16px 16px;
					}
				}
			}
		}
		&.active {
			.menu {
				.nav-txt,.user {
					transition-delay: 0.5s;
					pointer-events: auto;
					opacity: 1;
					transform: translate3d(0, 0, 0);
					-webkit-transform: translate3d(0, 0, 0);
					-moz-transform: translate3d(0, 0, 0);
					-o-transform: translate3d(0, 0, 0);
					-ms-transform: translate3d(0, 0, 0);
				}
				.nav-txt{
					&.login{
						right: 13.5em;
					}
				}
				> ul {
					opacity: 0;
					filter: blur(3px);
					pointer-events: none;
					transform: translate3d(30px, 0, 0);
					-webkit-transform: translate3d(30px, 0, 0);
					-moz-transform: translate3d(30px, 0, 0);
					-o-transform: translate3d(30px, 0, 0);
					-ms-transform: translate3d(30px, 0, 0);
				}
			}
			&.on{
				.menu{
					.nav-txt{
						&.login{
							right: 12em;
						}
					}
				}
			}
		}
	
		.menu {
			font-size: 14px;
			position: relative;
			width: calc(100% - 178px);
			padding-right: 30px;
			justify-content: space-between;
			transition: all .3s linear;
			ul.nav-items {
				pointer-events: auto;
				height: 60px;
				justify-content: flex-end;
				width: 100%;
				transition: all 0.5s ease-in-out;
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				-ms-transition: all 0.5s ease-in-out;
	
				li {
					padding: 0 1vw;
					position: relative;
					&:hover{
						a{
							background-color: #fff;
							color: #3C78DD;
							span{
								color: #3C78DD;
							}
						}
					}
					&.active{
						a{
							color: #FFF;
						}
					}
					.login{
						span{
							padding: 0 10px;
							cursor: pointer;
							font-weight: bold;
							color: #AEA7A7;
							font-size: 14px;
							&:hover{
								color: #FFF;
							}
						}
						em{
							width: 1px;
							height: 11px;
							background-color: #AEA7A7;
						}
					}
					a {
						position: relative;
						cursor: pointer;
						font-weight: bold;
						line-height: 40px;
						display: inline-block;
						padding: 0 1vw;
						border-radius: 50px;
						color: #AEA7A7;
						transition: all .5s linear;
						span {
							font-size: 14px;
							position: relative;
							z-index: 5;
							white-space: nowrap;
						}
					}
				}
	
				.nav-dot {
					position: relative;
					width: 4px;
					height: 4px;
					border-radius: 50%;
					line-height: 4px;
					text-align: center;
					background-color: #fff;
				}
			}
			.other-box{
				position: relative;
				z-index: 10;
				background: rgba(168, 168, 209, .25);
				border-radius: 50%;
				.menu-icon {
					width: 54px;
					height: 54px;
					background-image: url(../../assets/images/menu_icon.png);
					background-size: 17px 12px;
					background-position: center;
					background-repeat: no-repeat;
					border-radius: 50%;
					cursor: pointer;
					transition: all .3s linear;
				}				
			}
			.user{
				position: relative;
				background: rgba(168, 168, 209, .25);
				border-radius: 50%;
				margin-left: 1vw;
				&:hover{
					.user-menu{
						visibility: visible;
						transform: translate(-50%,0);
						opacity: 1;
					}
				}
				.user-icon{
					width: 54px;
					height: 54px;
					background-image: url(../../assets/images/user1.png);
					background-size: 20px 20px;
					background-position: center;
					background-repeat: no-repeat;
					border-radius: 50%;
					cursor: pointer;
					transition: all .3s linear;
				}
				.user-menu{
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 2.5em;
					left: 50%;
					width: 8vw;
					padding-top: 5vh;
					transform: translate(-50%,30px);
					color: #FFF;
					z-index: 5;
					transition: all .3s linear;
					ul{
						background: rgba(168, 168, 209, .25);
						padding: 1vh 0;
						border-radius: 10px;
						li{
							padding: 1.5vh 0;
							cursor: pointer;
						}
					}
				}
			}
	
			.nav-txt {
				position: absolute;
				opacity: 0;
				pointer-events: none;
				right: 8em;
				transform: translate3d(10px, 0, 0);
				-webkit-transform: translate3d(10px, 0, 0);
				-moz-transform: translate3d(10px, 0, 0);
				-o-transform: translate3d(10px, 0, 0);
				-ms-transform: translate3d(10px, 0, 0);
				height: 60px;
				justify-content: flex-end;
				transition: all 0.3s ease-in-out;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				-o-transition: all 0.3s ease-in-out;
				-ms-transition: all 0.3s ease-in-out;
				// &.login{
				// 	right: 12vw;
				// }
				span {
					color: #FFF;
					transition: all 0.3s ease-in-out;
					-webkit-transition: all 0.3s ease-in-out;
					-moz-transition: all 0.3s ease-in-out;
					-o-transition: all 0.3s ease-in-out;
					-ms-transition: all 0.3s ease-in-out;
				}
	
				em {
					margin-left: 8px;
					transition: all 0.3s ease-in-out;
					-webkit-transition: all 0.3s ease-in-out;
					-moz-transition: all 0.3s ease-in-out;
					-o-transition: all 0.3s ease-in-out;
					-ms-transition: all 0.3s ease-in-out;
					width: 0;
					height: 0;
					border-width: 5px;
					border-style: solid;
					border-color: transparent transparent transparent #fff;
				}
			}
		}
	}
	@media (max-width: 1440px){
		nav{
			padding: 1%;
			.logo {
				img {
					width: 130px;
				}
			}
			.menu{
				font-size: 12px !important;
				.menu-icon {
					width: 50px;
					height: 50px;
				}
				ul{
					li{
						a{
							line-height: 34px;
						}
					}
				}
			}
		}
	}
</style>
