import axios from 'axios'
// import store from '@/store'
import config from '@/config'
import store from '@/store'
import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
    baseURL: config.baseURL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
        // do something before request is sent
	if (localStorage.getItem('token')) {
		// let each request carry token
		// ['X-Token'] is a custom headers key
		// please modify it according to the actual situation
		config.headers['X-Access-Token'] = localStorage.getItem('token')
	}
	return config
	},
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code === 200 || res.code === 0 || res.repCode === '0000') {
            return res
        } else {
            Message({
                message: res.message || res.repMsg,
                type: 'error'
            });
			return res
        }
        // return Promise.reject(new Error(res.message || 'Error'))
    },
    error => {
		// console.log(error)
        if(error.response.status === 500){
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
            store.state.user.token = ''
            store.state.user.info = null
            store.state.user.isLogin = false
        }
        Message({
            message: error.response.data.message,
            type: 'error'
        });
        return Promise.reject(error)
    }
)

export default service
