<template>
	<div>
		<el-dialog :visible.sync="showDialog" :modal="false" :show-close="false" destroy-on-close append-to-body width="50vw" custom-class="login-dialog">
			<div class="login-wrap flex-row">
				<div class="left">
					<img src="../../assets/images/login_bg.png" />
				</div>
				<div class="right flex-col">
					<div class="close-dialog" @click="closeDialog">
						<img src="../../assets/images/close_icon.png" />
					</div>
					<h1 class="ft-20 t-center">欢迎登录</h1>
					<el-form ref="loginForm" :model="form" :rules="rules" hide-required-asterisk>
						<el-form-item prop="username">
							<el-input v-model="form.username" placeholder="请输入手机号" clearable autocomplete="off">
								<i slot="prefix" style="height: 100%;display: flex;align-items: center;">
									<img src="../../assets/images/user_icon.png" />
								</i>
							</el-input>
						</el-form-item>
						<el-form-item class="pwd-input" prop="password">
							<el-input v-model="form.password" placeholder="请输入密码" clearable show-password autocomplete="off">
								<i slot="prefix" style="height: 100%;display: flex;align-items: center;">
									<img src="../../assets/images/pwd_icon.png" />
								</i>
							</el-input>
						</el-form-item>
						<el-form-item class="checked-item">
							<el-checkbox v-model="checked">记住密码</el-checkbox>
						</el-form-item>
						<el-form-item class="login-button">
							<el-button type="primary" @click="onSubmit">登录</el-button>
						</el-form-item>
						<el-form-item>
							<div class="tips_wrap flex-row ft-14" style="justify-content: space-between;">
								<p>还没有账号?<a @click="showRegister">立即注册</a></p>
								<p><a @click="showResetPassword">忘记密码?</a></p>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-dialog>
		<reset-password ref="resetPassword"></reset-password>
	</div>
</template>

<script>
	import { login } from '@/api/login.js'
	import resetPassword from './resetPassword.vue'
	export default{
		name: 'login',
		components: {
			resetPassword
		},
		data() {
			return {
				showDialog: false,
				checked: false,
				form: {
					username: '',
					password: '',
				},
				rules: {
					username: [
						{ required: true, message: '请输入手机号码',trigger: 'blur'}
					],
					password: [
						{ required: true, message: '请输入密码',trigger: 'blur'}
					],
				}
			}
		},
		created() {
			if (localStorage.getItem('learn')) {
				let user = JSON.parse(localStorage.getItem('userLogin'))
				this.checked = true
				this.form.username = user.username
				this.form.password = user.password
			}
		},
		methods: {
			show() {
				this.showDialog = true
			},
			closeDialog() {
				this.showDialog = false
			},
			showRegister() {
				this.showDialog = false
				this.$emit('showRegister')
			},
			showResetPassword() {
				this.showDialog = false
				this.$refs.resetPassword.title = '忘记密码'
				this.$refs.resetPassword.show()
			},
			onSubmit() {
				this.$refs.loginForm.validate((valid) => {
					if (valid) {
						login(this.form).then(res =>{
							if (res.success) {
								if (this.checked) {
									localStorage.setItem('learn',1)
									localStorage.setItem('userLogin',JSON.stringify(this.form))
								} else {
									localStorage.removeItem('learn')
									localStorage.removeItem('userLogin')
								}
								this.$store.state.user.token = res.result.token
								this.$store.state.user.info = res.result.userInfo
								this.$store.state.user.isLogin = true
								localStorage.setItem('token',res.result.token)
								localStorage.setItem('userInfo',JSON.stringify(res.result.userInfo))
								this.showDialog = false
								this.$message.success(res.message)
								this.$router.go(0)
							} else {
								this.$message.error(res.message)
							}
						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	/deep/ .login-dialog{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		margin: 0!important;
		// height: 60vh;
		background: linear-gradient(141deg, #2A2A33 0%, #1A1A1F 100%);
		box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.53);
		border-radius: 20px;
		border: 1px solid #3D414F;
		overflow: hidden;
		.el-dialog__header{
			display: none;
		}
		.el-dialog__body{
			height: 100%;
			padding: 0;
			.el-input__inner{
				background: #0C0C10;
				border-radius: 4px;
				opacity: 0.9678;
				border: none;
				outline: none;
			}
			.login-wrap{
				position: relative;
				height: 100%;
				justify-content: space-between;
				.left{
					width: 40%;
					// height: 100%;
					img{
						display: block;
						width: 100%;
						// height: 100%;
					}
				}
				.right{
					height: 100%;
					width: 60%;
					// padding: 0 6vw;
					h1{
						color: #FFF;
						margin-bottom: 6vh;
					}
					.close-dialog{
						position: absolute;
						right: 1vw;
						top: 1vw;
						cursor: pointer;
						transition: all .3s ease;
						&:hover{
							opacity: .5;
						}
						img{
							display: block;
						}
					}
					.el-form{
						width: 64%;
						.el-form-item{
							&:last-child{margin-bottom: 0;}
							.el-checkbox__label{
								color: #FFF;
							}
							&.checked-item{
								.el-form-item__content{
									width: 100%;
									line-height: initial;
									display: flex;
									justify-content: flex-start;
									align-items: center;
								}
							}
							&.pwd-input{
								margin-bottom: 0;
							}
							&.login-button{
								margin-top: 5vh;
								margin-bottom: 0;
								.el-button{
									width: 100%;
								}
							}
							.tips_wrap{
								margin-top: 5px;
								p{
									a{
										cursor: pointer;
										color: #3C78DD;
									}
								}
							}
						}
						.el-checkbox{
							margin-top: 2.5vh;
						}
					}
				}
			}
		}
	}
</style>
