// 一些全局的config配置
const modeUrlObj = {
    // 生产环境
    'production': {
        // baseURL: 'https://www.haoyifilmgroup.com/jeecg-boot',
		baseURL: 'http://47.108.117.21:8089/jeecg-boot',
        authBaseURL: ''
    },
    // 开发环境
    'development': {
        //baseURL: 'http://192.168.10.241:8082/jeecg-boot',
		baseURL: 'http://47.108.117.21:8089/jeecg-boot',
        authBaseURL: ''
    },
    // 测试环境
    'test': {
        baseURL: 'http://192.168.10.241:8082/jeecg-boot',
        authBaseURL: ''
    }
}
// eslint-disable-next-line no-undef
export default modeUrlObj[process.env.NODE_ENV]
