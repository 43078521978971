<template>
	<el-dialog title="《用户服务协议》" :visible.sync="showDialog" lock-scroll modal destroy-on-close append-to-body width="50vw" custom-class="treaty-dialog">
		<div class="treaty-wrap">
			<p>浩亿影业平台（以下简称“平台”）依据本协议的规定为本平台注册会员提供服务，本协议在注册会员和平台间具有合同上的法律效力。在此特别提醒您认真阅读、充分理解本协议各条款，特别是其中所涉及的免除及限制平台责任的条款、对注册会员权利限制条款等。请您审慎阅读并选择接受或不接受本协议，会员一经注册或使用平台服务即视为对本协议全部条款已充分理解并完全接受。</p>
			
			<h1 class="ft-16">（一）注册会员</h1>
			<p>1、平台注册会员应当是符合中华人民共和国法律规定的具有完全的民事权利能力和民事行为能力，能够独立承担民事责任的自然人。在使用平台服务前，会员必须先在平台上进行注册。</p>
			<p>2、注册会员应当承诺以下事项：</p>
			<p class="sub">（1）会员必须依平台要求提示提供真实、有效及完整的资料。</p>
			<p class="sub">（2）已注册会员有义务维持并更新会员资料，确保其为真实、有效及完整。</p>
			<p>若注册会员提供任何错误、虚假、过时或不完整的资料，或者平台依其独立判断怀疑资料为错误、虚假、过时或不完整，平台有权暂停或终止注册会员的会员账户，并拒绝为其提供部份或全部的平台服务功能。在此情况下，平台不承担任何责任，并且注册会员同意负担因此所产生的直接或间接的任何损失。</p>
			<p class="sub">（3）如因会员未及时更新基本资料，导致平台服务无法提供或提供时发生任何错误，会员不得将此作为取消交易或拒绝付款的理由，平台对此不承担任何责任。</p>
			
			<h1 class="ft-16">（二）账户安全</h1>
			<p>1、注册会员应当确保会员注册账户及密码的机密安全。</p>
			<p>2、注册会员将对利用该会员账户及密码所进行的一切行动及言论，负完全的责任，并同意以下事项：</p>
			<p class="sub">（1）注册会员不对其他任何人泄露账户或密码，亦不可使用其他任何人的账户或密码。因黑客、病毒或注册会员的保管疏忽等非平台原因导致注册会员的会员账户遭他人非法使用的，平台不承担任何责任。</p>
			<p class="sub">（2）平台通过注册会员的会员账户及密码来识别注册会员的指令，注册会员确认，使用注册会员账户和密码登陆后在平台的一切行为均代表注册会员本人。会员账户操作所产生的电子信息记录均为注册会员行为的有效凭据，并由注册会员本人承担由此产生的全部责任。</p>
			<p class="sub">（3）冒用他人账户及密码的，平台及其合法授权主体保留追究实际使用人连带责任的权利。</p>
			<p class="sub">（4）注册会员应根据平台的相关规则及提示创建一个安全密码，应避免选择过于明显的单词或日期，比如注册会员的姓名、昵称或者生日等。</p>
			<p>3、注册会员如发现有第三人冒用或盗用注册会员账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知平台，要求平台暂停相关服务，否则由此产生的一切责任由注册会员本人承担。同时，注册会员理解平台对注册会员的请求采取行动需要合理期限，在此之前，平台对第三人使用该服务所导致的损失。</p>
			<p>4、会员死亡或被宣告死亡的，其在本协议项下的各项权利义务由其继承人承担。若会员丧失全部或部分民事权利能力或民事行为能力，平台或其授权的主体有权根据有效法律文书（包括但不限于生效的法院判决等）或其法定监护人的指示处置会员权益。</p>
			<p>5、平台有权基于单方独立判断，在其认为可能发生危害交易安全等情形时，不经通知而先行暂停、中断或终止向注册会员提供本协议项下的全部或部分会员服务，并将注册资料移除或删除，且无需对注册会员或任何第三方承担任何责任。前述情形包括但不限于：</p>
			<p class="sub">（1）平台认为注册会员提供的个人资料不具有真实性、有效性或完整性；</p>
			<p class="sub">（2）平台发现异常交易或有疑义或有违法之虞时；</p>
			<p class="sub">（3）平台认为注册会员账户涉嫌洗钱、套现、传销、被冒用或其他平台认为有风险之情形；</p>
			<p class="sub">（4）平台认为注册会员已经违反本协议中规定的各类规则及精神；</p>
			<p class="sub">（5）注册会员在使用平台收费服务时未按规定向平台支付相应的服务费用；</p>
			<p class="sub">（6）注册会员账户已连续三年内未实际使用且账户中余额为零；</p>
			<p class="sub">（7）平台基于交易安全等原因，根据其单独判断需先行暂停、中断或终止向注册会员提供本协议项下的全部或部分会员服务（包括收费服务），并将注册资料移除或删除的其他情形。</p>
			<p>6、注册会员同意，会员账户的暂停、中断或终止不代表注册会员责任的终止，注册会员仍应对使用平台服务期间的行为承担可能的违约或损害赔偿责任，同时平台仍可保有注册会员的相关信息。</p>
			
			<h1 class="ft-16">（三）会员的义务及承诺</h1>
			<p>1、注册会员承诺绝不为任何非法目的或以任何非法方式使用平台服务，并承诺遵守中国相关法律、法规及一切使用互联网之国际惯例，遵守所有与平台服务有关的网络协议、规则和程序。</p>
			<p>2、注册会员同意并保证不得利用平台服务从事侵害他人权益或违法之行为，若有违反者应负所有法律责任。上述行为包括但不限于：</p>
			<p class="sub">（1）反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一的。</p>
			<p class="sub">（2）侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权、其他知识产权及其他权益。</p>
			<p class="sub">（3）违反依法律或合约所应负之保密义务。</p>
			<p class="sub">（4）冒用他人名义使用平台服务。</p>
			<p class="sub">（5）从事任何不法交易行为，如贩卖枪支、毒品、禁药、盗版软件或其他违禁物。</p>
			<p class="sub">（6）提供赌博资讯或以任何方式引诱他人参与赌博。</p>
			<p class="sub">（7）涉嫌洗钱、套现或进行传销活动的。</p>
			<p class="sub">（8）从事任何可能含有电脑病毒或是可能侵害平台服务系統、资料等行为。</p>
			<p class="sub">（9）利用平台服务系统进行可能对互联网或移动网正常运转造成不利影响之行为。</p>
			<p class="sub">（10）侵犯平台的商业利益，包括但不限于发布非经平台许可的商业广告。</p>
			<p class="sub">（11）利用平台服务上传、展示或传播虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料。</p>
			<p class="sub">（12）其他平台有正当理由认为不适当之行为。</p>
			<p>3、平台保有依其单独判断删除平台内各类不符合法律政策或不真实或不适当的信息内容而无须通知会员的权利，并无需承担任何责任。若注册会员未遵守以上规定的，平台有权作出独立判断并采取暂停或关闭会员账户等措施，而无需承担任何责任。</p>
			<p>4、注册会员同意，由于注册会员违反本协议，或违反通过援引并入本协议并成为本协议一部分的文件，或由于注册会员使用平台服务违反了任何法律或第三方的权利而造成任何第三方进行或发起的任何补偿申请或要求（包括律师费用），注册会员会对平台及其关联方、合作伙伴、董事以及雇员给予全额补偿并使之不受损害。</p>
			<p>5、注册会员承诺，其通过平台平台上传或发布的信息均真实有效，其向平台提交的任何资料均真实、有效、完整、详细、准确。如因违背上述承诺，造成平台或平台其他使用方损失的，注册会员将承担相应责任。</p>
			
			<h1 class="ft-16">（四）服务中断或故障</h1>
			<p>注册会员同意，基于互联网的特殊性，平台不担保服务不会中断，也不担保服务的及时性和/或安全性。系统因相关状况无法正常运作，使注册会员无法使用任何平台服务或使用任何平台服务时受到任何影响时，平台对注册会员或第三方不负任何责任，前述状况包括但不限于：</p>
			<p class="sub">（1）平台系统停机维护期间。</p>
			<p class="sub">（2）电信设备出现故障不能进行数据传输的。</p>
			<p class="sub">（3）由于黑客攻击、网络供应商技术调整或故障、网站升级、银行方面的问题等原因而造成的平台服务中断或延迟。</p>
			<p class="sub">（4）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成平台系统障碍不能执行业务的。</p>
			
			<h1 class="ft-16">（五）责任范围及限制</h1>
			<p>1、平台未对任何平台服务提供任何形式的保证，包括但不限于以下事项：</p>
			<p class="sub">（1）平台服务将符合注册会员的需求。</p>
			<p class="sub">（2）平台服务将不受干扰、及时提供或免于出错。</p>
			<p class="sub">（3）注册会员经由平台服务购买或取得之任何产品、服务、资讯或其他资料将符合注册会员的期望。</p>
			<p>2、平台服务的合作单位所提供的服务品质及内容由该合作单位自行负责。平台的内容可能涉及由第三方所有、控制或者运营的其他网站（以下简称“第三方网站”）。平台不能保证也没有义务保证第三方网站上任何信息的真实性和有效性。注册会员确认按照第三方网站的服务协议使用第三方网站，而不是按照本协议。第三方网站不是平台推荐或者介绍的，第三方网站的内容、产品、广告和其他任何信息均由注册会员自行判断并承担风险，而与平台无关。注册会员经由平台服务的使用下载或取得任何资料，应由注册会员自行考量且自负风险，因资料的下载而导致的任何损坏由注册会员自行承担。</p>
			<p>3、注册会员自平台及平台工作人员或经由平台服务取得的建议或资讯，无论其为书面或口头，均不构成平台对平台服务的任何保证。</p>
			<p>4、平台不保证为向注册会员提供便利而设置的外部链接的准确性、有效性、安全性和完整性。同时，对于该等外部链接指向的不由平台实际控制的任何网页上的内容，平台不承担任何责任。</p>
			<p>5、在法律允许的情况下，平台对于与本协议有关或由本协议引起的，或者，由于使用平台、或由于其所包含的或以其他方式通过平台提供给注册会员的全部信息、内容、材料、产品（包括软件）和服务、或购买和使用产品引起的任何间接的、惩罚性的、特殊的、派生的损失（包括但不限于业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使其事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除平台对上述损失的责任。</p>
			
			<h1 class="ft-16">（六）隐私权保护及授权条款</h1>
			<p>1、平台对于注册会员提供的、平台自行收集的、经认证的个人信息将依法予以保护、使用或者披露。平台无需注册会员同意即可向平台关联实体转让与平台有关的全部或部分权利和义务。未经平台事先书面同意，注册会员不得转让其在本协议项下的任何权利和义务。</p>
			<p>2、平台可能自公开及私人资料来源收集注册会员的额外资料，以更好地掌握注册会员情况，并为注册会员度身订造平台服务、解决争议并有助确保在平台平台进行安全交易。</p>
			<p>3、平台按照注册会员在平台上的行为自动追踪关于注册会员的某些资料。在不透露注册会员的隐私资料的前提下，平台有权对整个会员数据库进行分析并对会员数据库进行商业上的利用。</p>
			<p>4、注册会员同意平台可使用关于注册会员的相关资料（包括但不限于平台持有的有关注册会员的档案中的资料，平台从注册会员目前及以前在平台平台上的活动所获取的其他资料以及平台通过其他方式自行收集的资料）以解决争议、对纠纷进行调停。注册会员同意平台可通过人工或自动程序对注册会员的资料进行评价。</p>
			
			<h1 class="ft-16">（七）争端解决</h1>
			<p>1、本协议书不涉及注册会员与平台的其他会员之间，因网上交易而产生的法律关系及法律纠纷。</p>
			<p>2、平台对本协议书拥有最终的解释权。</p>
			<p>3、本说明书及其修订的有效性、履行与本协议及其修订效力有关的所有事宜，将受中国法律管辖，任何争议仅适用中国法律。</p>
			<p>本协议签订地为中国厦门市。因本协议所引起的会员与平台之间的任何争议，协商不成的，注册会员同意将纠纷或争议提交平台办公运营所在地有管辖权的人民法院诉讼解决。</p>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				showDialog: false
			}
		},
		methods: {
			show() {
				this.showDialog = true
			},
			close() {
				this.showDialog = false
			}
		}
	}
</script>

<style lang="scss" scoped>
	/* …… 重置系统滚动条 …… */
	::-webkit-scrollbar {display: none; background:rgba(50,50,50,.5);border-radius:0;height:3px;width:3px; transition:all .3s ease-in-out;}
	::-webkit-scrollbar-track {background-color: #f3f3f3;}
	::-webkit-scrollbar-track-piece {background: #f3f3f3;}
	::-webkit-scrollbar-thumb {background:rgba(0,0,0,.3);border-radius:0;min-height:15px;width:3px; transition:all .3s ease-in-out;}
	::-webkit-scrollbar-thumb:hover {background:rgba(0,0,0,.5)}
	::-webkit-scrollbar-thumb:active {background:rgba(0,0,0,.5)}
	
	/*在mac上，增加此设置，会导致滚动条不能自动显示与隐藏，滚动条会一直显示 */
	::-webkit-scrollbar {
		width: 8px;
	}
	
	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		border-radius: 6px;
		background: rgba(0,0,0,0.1);
	}
	// 兼容火狐
	/* 滑块颜色  滚动条背景颜色 */
	/* 滚动条宽度有三种：thin、auto、none */
	* {
		scrollbar-color: #e5e5e5 #f7f7f9; 
		scrollbar-width: none; 
	}
	/deep/ .treaty-dialog{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		margin: 0!important;
		height: 80vh;
		background: linear-gradient(141deg, #2A2A33 0%, #1A1A1F 100%);
		box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.53);
		border-radius: 10px;
		opacity: 0.98;
		border: 5px solid rgba(255, 255, 255, 0.2);
		color: #FFF;
		overflow: hidden;
		.el-dialog__header{
			height: 7.5vh;
			text-align: center;
			.el-dialog__title{
				color: #FFF;
			}
		}
		.el-dialog__body{
			height: calc(100% - 7.5vh);
			overflow: hidden;
			padding: 0 30px 30px;
			color: #FFF;
			.treaty-wrap{
				height: 100%;
				padding: 24px;
				overflow: auto;
				// border: 1px solid #41414A;
				font-size: 12px;
				p{
					line-height: 2.5vh;
					text-indent: 20px;
				}
			}
		}
	}
</style>
