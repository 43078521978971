<template>
	<div>
		<el-dialog :visible.sync="showDialog" :modal="false" :show-close="false" destroy-on-close append-to-body width="45vw" custom-class="password-dialog">
			<div class="register-wrap flex-row">
				<div class="right">
					<div class="close-dialog" @click="closeDialog">
						<img src="@/assets/images/close_icon.png" />
					</div>
					<h1 class="ft-18 t-center">{{title}}</h1>
					<el-form ref="updatePwd" :model="form" :rules="rules" hide-required-asterisk label-width="5vw">
						<el-form-item label="手机号码" prop="phone">
							<el-input v-model="form.phone" placeholder="请输入手机号码" clearable autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item label="验证码" class="code-item" prop="smscode">
							<el-input v-model="form.smscode" clearable placeholder="请输入短信验证码" autocomplete="off">
								<el-button slot="append" v-if="timerTime > 0">{{timerTime}} 秒后重新获取</el-button>
								<el-button slot="append" v-else @click="showVerify">获取验证码</el-button>
							</el-input>
						</el-form-item>
						<el-form-item label="密码" prop="password">
							<el-input v-model="form.password" placeholder="新密码由6-12位、数字字母组成" clearable show-password autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item class="login-button">
							<el-button type="primary" @click="onSubmit">确认</el-button>
						</el-form-item>
					</el-form>
					<Verify
						@success="verifySuccess"
						:mode="'pop'"
						captchaType="clickWord"
						:imgSize="{ width: '330px', height: '155px' }"
						ref="verify"
					></Verify>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Verify from '@/components/verifition/Verify.vue'
	import { getSmsCaptcha,updatePwd } from '@/api/login.js'
	export default {
		name:"resetpassword",
		components: {
			Verify
		},
		data() {
			var captchaTypeValue = 'clickWord'
			var min = 0
			var max = 100
			var random = Math.floor(Math.random() * (max - min)) + min
			if (random % 2 === 0) {
				captchaTypeValue = 'blockPuzzle'
			}
			if (random % 2 === 1) {
				captchaTypeValue = 'clickWord'
			}
			return {
				title: '',
				showDialog: false,
				timerTime: 0, // 手机验证码发送时间
				captchaType: captchaTypeValue,
				form: {
					phone: '',
					smscode: '',
					password: '',
				},
				rules: {
					smscode: [
						{ required: true, message: '请输入短信验证码',trigger: 'blur'},
					],
					phone: [
						{ required: true, message: '请输入手机号码',trigger: 'blur'},
						{ pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码' }
					],
					password: [
						{ required: true, message: '请设置6-12位登录密码',trigger: 'blur' },
						{ pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/, message: '密码须包含数字、字母两种元素，且密码位数为6-12位' }
					]
				}
			}
		},
		methods: {
			showVerify() {
				let that = this
				this.$refs.updatePwd.validateField('phone', (mobile_check) => {
					if(mobile_check) return
					that.$refs.verify.show()
				})
			},
			show() {
				this.showDialog = true
			},
			closeDialog() {
				this.showDialog = false
			},
			/**
			 * 忘记密码获取验证码
			 */
			verifySuccess(params) {
				let data = {
					mobile: this.form.phone,
					smsmode: '2',
					code: params.captchaVerification
				}
				getSmsCaptcha(data).then(res => {
					if (res.success) {
						this.$message.success('短信验证码发送成功')
						this.timerTime = 60
						this.timer = setInterval(() => {
							if (this.timerTime > 0) {
								this.timerTime--
							} else {
								this.timer = null
								clearInterval(this.timer)
							}
						}, 1000)
					} else {
						this.$message.error(res.message)
					}
				})
			},
			onSubmit() {
				this.$refs.updatePwd.validate((valid) => {
					if (valid) {
						updatePwd(this.form).then(res =>{
							if (res.success) {
								this.showDialog = false
								this.$refs.updatePwd.resetFields()
								this.$message.success('密码修改成功，请重新登录！')
								localStorage.removeItem('token')
								localStorage.removeItem('userInfo')
								this.$store.state.user.token = ''
								this.$store.state.user.info = null
								this.$store.state.user.isLogin = false
								setTimeout(() =>{
									this.$router.push('/')
								},1000) 
							} else {
								this.$message.error(res.message)
							}
						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mask{
		height: 60vh;
	}
	/deep/ .password-dialog{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		margin: 0!important;
		// height: 58vh;
		// background: linear-gradient(141deg, #2A2A33 0%, #1A1A1F 100%);
		// box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.53);
		background: linear-gradient(141deg, #2A2A33 0%, #1A1A1F 100%);
		box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.53);
		border-radius: 20px;
		border: 1px solid #3D414F;
		overflow: hidden;
		.el-dialog__header{
			display: none;
		}
		.el-dialog__body{
			height: 100%;
			padding: 0;
			.el-input__inner{
				background: #0C0C10;
				border-radius: 4px;
				opacity: 0.9678;
				border: none;
				outline: none;
			}
			.register-wrap{
				height: 100%;
				justify-content: space-between;
				.right{
					position: relative;
					height: 100%;
					width: 100%;
					padding: 3vw 2vw 4vw 0;
					h1{
						color: #FFF;
						margin-bottom: 6vh;
					}
					.close-dialog{
						position: absolute;
						right: 1vw;
						top: 1vw;
						cursor: pointer;
						transition: all .3s ease;
						&:hover{
							opacity: .5;
						}
						img{
							display: block;
						}
					}
					.el-form{
						width: 60%;
						margin-left: 18%;
						.el-form-item{
							.el-form-item__label{
								color: #FFF;
							}
							&.code-item{
								.el-form-item__content{
									.el-input{
										.el-input__inner{
											border-radius: 4px 0 0 4px;
										}
										.el-input-group__append{
											color: #3C78DD;
											border: none;
											outline: none;
											background-color: #0C0C10;
											.el-button{
												height: 40px;
												padding: 0 10px;
											}
										}
									}
								}
							}
							&.login-button{
								margin-top: 6vh;
								margin-bottom: 0;
								.el-button{
									width: 100%;
								}
							}
							.tips_wrap{
								p{
									a{
										cursor: pointer;
										color: #3C78DD;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>
