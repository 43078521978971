<template>
	<div>
		<el-dialog :visible.sync="showDialog" :modal="false" :show-close="false" destroy-on-close append-to-body width="40vw" custom-class="error-dialog">
			<div class="login-wrap flex-row">
				<div class="right t-center">
					<div class="close-dialog" @click="closeDialog">
						<img src="../../assets/images/close_icon.png" />
					</div>
					<div class="success-icon flex-row">
						<img src="../../assets/images/error_icon.png" />
					</div>
					<h1 class="ft-24 t-center">提示您</h1>
					<p class="ft-16 t-center">{{title}}</p>
					<el-button type="primary" @click="showDialog = false">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showDialog: false,
				title: ''
			}
		},
		methods: {
			closeDialog() {
				this.showDialog = false
			},
			show() {
				this.showDialog = true
			}
		}
	}
</script>

<style lang="scss" scoped>
	/deep/ .error-dialog{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		margin: 0!important;
		height: 50vh;
		background: linear-gradient(141deg, #2A2A33 0%, #1A1A1F 100%);
		box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.53);
		border: 1px solid #3D414F;
		border-radius: 20px;
		overflow: hidden;
		.el-dialog__header{
			display: none;
		}
		.el-dialog__body{
			height: 100%;
			padding: 0;
			.login-wrap{
				height: 100%;
				justify-content: space-between;
				.right{
					position: relative;
					height: 100%;
					width: 100%;
					padding: 4vw 6vw;
					h1{
						color: #FFF;
						margin-bottom: 1vh;
					}
					p{
						color: #FFF;
					}
					.el-button{
						width: 60%;
						margin: 6vh auto 0;
					}
					.close-dialog{
						position: absolute;
						right: 1vw;
						top: 1vw;
						cursor: pointer;
						transition: all .3s ease;
						&:hover{
							opacity: .5;
						}
						img{
							display: block;
						}
					}
					.success-icon{
						margin-bottom: 2vh;
						img{
							display: block;
							width: 4vw;
						}
					}
				}
			}
		}
	}
</style>
