import http from '@/utils/request'

/**
 * 获取短信验证码
 */
export function getSmsCaptcha (parameter) {
  return http.post('/sys/sms',parameter)
}

/**
 * 获取验证图片 以及token
 */
export function reqGet(data) {
  return http.post('/captcha/get',data)
}

/**
 * 滑动或者点选验证
 */
export function reqCheck(data) {
  return  http.post('/captcha/check',data)
}

/**
*	注册
*/ 
export function register(data) {
	return http.post('/sys/user/register',data)
}

/**
 * 修改密码
 */ 
export function updatePwd(data) {
	return http.get('/sys/user/passwordChange',{params:data})
}

/**
 * 登录
 */ 
export function login(data) {
	return http.post('/sys/websiteLogin',data)
}

/**
 * 退出登录
 */ 
export function loginOut(data) {
	return http.get('/sys/logout',{data})
}
